import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Alert,
  Button
} from '@mui/material';
import { useUser } from '@clerk/clerk-react';

function getNetwork(partner) {
  let network;

  if (!partner || partner === "Village Media") {
    network = "5198809"
  } else if (partner === "Dougall Media") {
    network = "21658289790,121051394"
  } else if (partner === "BK Reader") {
    network = "21842630800"
  } else if (partner === "Grice Connect") {
    network = "22111401400"
  } else if (partner === "Great West Media") {
    network = "4326737"
  }

  return network
}

function getAds(partner) {

  let ads = {};

  if (!partner || partner === "Village Media") {
    ads = {
      units: [
        {
          id: "NET_Newsletter",
          size: "600x155%7C600x450"
        },
        {
          id: "NET_Newsletter_Mid",
          size: "970x250%7C600x155"
        },
        {
          id: "Trillium_TheT_Newsletter",
          size: "600x155"
        },
        {
          id: "Trillium_AM_Newsletter",
          size: "600x155"
        },
        {
          id: "Trillium_PM_Newsletter",
          size: "600x155"
        },
        {
          id: "NET_Email_Alert",
          size: "636x34"
        },
        {
          id: "NET_Email_Weather_Sponsor",
          size: "600x155"
        },
        {
          id: "NET_Email_Events_Sponsor",
          size: "600x155"
        },
        {
          id: "NET_Spaces_Feature",
          size: "600x300"
        }
      ],
      placements: [
        {
          placement: "1"
        },
        {
          placement: "2"
        },
        {
          placement: "3"
        },
        {
          placement: "4"
        },
        {
          placement: "5"
        },
        {
          placement: "6"
        },
        {
          placement: "7"
        },
        {
          placement: "8"
        },
        {
          placement: "9"
        },
      ]
    }
  } else if (partner === "Dougall Media") {
    ads = {
      units: [
        {
          id: "nwonewswatch/NWONW_Newsletter",
          size: "600x155%7C600x450"
        },
        {
          id: "nwonewswatch/NWONW_Newsletter_Alert_Banner",
          size: "636x34"
        },
        {
          id: "nwonewswatch/NWONW_Newsletter_Lower",
          size: "600x155"
        },
        {
          id: "snnewswatch/SNNW_Newsletter",
          size: "600x155%7C600x450"
        },
        {
          id: "snnewswatch/SNNW_Newsletter_Alert_Banner",
          size: "636x34"
        },
        {
          id: "snnewswatch/SNNW_Newsletter_Lower",
          size: "600x155"
        },
        {
          id: "tbnewswatch/TBNW_Newsletter",
          size: "600x155%7C600x450"
        },
        {
          id: "tbnewswatch/TBNW_Newsletter_Mid_1",
          size: "970x250"
        },
        {
          id: "tbnewswatch/TBNW_Newsletter_Mid_2",
          size: "970x250"
        },
        {
          id: "tbnewswatch/TBNW_Newsletter_Mid_3",
          size: "970x250"
        },
        {
          id: "tbnewswatch/TBNW_Newsletter_Mid_4",
          size: "970x250"
        },
        {
          id: "tbnewswatch/TBNW_Newsletter_Mid_5",
          size: "970x250"
        },
        {
          id: "tbnewswatch/TBNW_Newsletter_Bottom",
          size: "970x250"
        },
        {
          id: "tbnewswatch/TBNW_Newsletter_Alert_Banner",
          size: "636x34"
        },
        {
          id: "tbnewswatch/TBNW_Newsletter_Lower",
          size: "600x155"
        }
      ],
      placements: [
        {
          placement: "Alert"
        },
        {
          placement: "Top"
        },
        {
          placement: "Mid_1"
        },
        {
          placement: "Mid_2"
        },
        {
          placement: "Mid_3"
        },
        {
          placement: "Mid_4"
        },
        {
          placement: "Mid_5"
        },
        {
          placement: "Bottom"
        },
      ]
    }
  } else if (partner === "BK Reader") {
    ads = {
      units: [
        {
          id: "NET_Newsletter",
          size: "600x155%7C600x450"
        },
        {
          id: "NET_Newsletter_Mid",
          size: "600x155"
        }
      ],
      placements: [
        {
          placement: "Alert"
        },
        {
          placement: "Top"
        },
        {
          placement: "Mid_1"
        },
        {
          placement: "Mid_2"
        },
        {
          placement: "Mid_3"
        },
        {
          placement: "Mid_4"
        },
        {
          placement: "Mid_5"
        },
      ]
    }
  } else if (partner === "Grice Connect") {
    ads = {
      units: [
        {
          id: "NET_Newsletter",
          size: "970x250"
        },
        {
          id: "NET_Newsletter_Mid",
          size: "970x250"
        }
      ],
      placements: [
        {
          placement: "Alert"
        },
        {
          placement: "Top"
        },
        {
          placement: "Mid_1"
        },
        {
          placement: "Mid_2"
        },
        {
          placement: "Mid_3"
        },
        {
          placement: "Mid_4"
        },
        {
          placement: "Mid_5"
        },
      ]
    }
  } else if (partner === "The Sarnia Journal") {
    ads = {
      units: [
        {
          id: "NET_Newsletter",
          size: "970x250"
        },
        {
          id: "NET_Newsletter_Mid",
          size: "970x250"
        }
      ],
      placements: [
        {
          placement: "Alert"
        },
        {
          placement: "Top"
        },
        {
          placement: "Mid_1"
        },
        {
          placement: "Mid_2"
        },
        {
          placement: "Mid_3"
        },
        {
          placement: "Mid_4"
        },
        {
          placement: "Mid_5"
        },
      ],
      targeting: [
        {
          key: "EmailDay",
          value: "*|DATE:D|*"
        }
      ]
    }
  } else if (partner === "Great West Media") {
    ads = {
      units: [
        {
          id: "sax_GW_site_section_subsection_970x250_S1_newsletter_GNG-network",
          size: "970x250"
        }
      ],
      placements: [
        {
          placement: "Alert"
        },
        {
          placement: "Top"
        },
        {
          placement: "Mid_1"
        },
        {
          placement: "Mid_2"
        },
        {
          placement: "Mid_3"
        },
        {
          placement: "Mid_4"
        },
        {
          placement: "Mid_5"
        },
      ],
      targeting: [
        {
          key: "EmailDay",
          value: "*|DATE:D|*"
        }
      ],
      groups: [
        {
          group: "Airdrie Daily Headlines"
        },
        {
          group: "Rocky View Daily Headlines"
        },
        {
          group: "Daily Headlines"
        },
        {
          group: "Contest Alerts"
        },
        {
          group: "Obituary Notices"
        },
        {
          group: "Hot Summer Guide"
        },
        {
          group: "Cool Winter Guide"
        },
        {
          group: "The Puck Drops Here"
        },
        {
          group: "Bow Valley Real Estate"
        },
      ],
      topLevelUnits: [
        {
          parent: 'airdrietoday/',
          siteId: '30'
        },
        {
          parent: 'JasperFitzhugh/',
          siteId: '124'
        },
        {
          parent: 'cochranetoday/',
          siteId: '32'
        },
        {
          parent: 'lakelandtoday/',
          siteId: '40'
        },
        {
          parent: 'mountainviewtoday/',
          siteId: '38'
        },
        {
          parent: 'okotokstoday/',
          siteId: '31'
        },
        {
          parent: 'rmotoday/',
          siteId: '33'
        },
        {
          parent: 'stalberttoday/',
          siteId: '28'
        },
        {
          parent: 'townandcountrytoday/',
          siteId: '39'
        }
      ]
    }
  }

  return ads
}

function AdUnits() {

  const { user } = useUser();
  const partnerSite = user?.publicMetadata?.partnerSite || "Village Media";

  const ads = getAds(partnerSite)
  const network = getNetwork(partnerSite);

  const [adUnit, setAdUnit] = useState({});

  const [sites, setSites] = useState([]);

  useEffect(() => {
    // Fetch audiences on component mount
    fetch('/api/sites?api_key=a51fd6e0-4f3c-4e22-b0c9-53a27b3a809f')
      .then((res) => res.json())
      .then((data) => {
        setSites(data.results || []);
      })
      .catch((error) => console.error('Error fetching sites:', error));
  }, []);


  const [alert, setAlert] = useState();
  const [preview, setPreview] = useState();

  const gamAd = `  <a href="https://securepubads.g.doubleclick.net/gampad/jump?iu=%2F${network}%2F${adUnit.ad}&sz=${adUnit.size}&clkk=*|UNIQID|*_*|CAMPAIGN_UID|*_*|DATE:d/m/y|*&ptt=21&t=Site%3D${adUnit.site}%26email_pos%3D${adUnit.placement}&clkp=${adUnit.placement}&url=us8.campaign-archive.com%252F%253Fe%253D*|UNIQID|*%2526u%253D*|EMAIL_UID|*%2526id%253D*|CAMPAIGN_UID|*" target="_blank">
    <img src="https://securepubads.g.doubleclick.net/gampad/ad?iu=%2F${network}%2F${adUnit.ad}&sz=${adUnit.size}&clkk=*|UNIQID|*_*|CAMPAIGN_UID|*_*|DATE:d/m/y|*&ptt=21&t=Site%3D${adUnit.site}%26email_pos%3D${adUnit.placement}&clkp=${adUnit.placement}&url=us8.campaign-archive.com%252F%253Fe%253D*|UNIQID|*%2526u%253D*|EMAIL_UID|*%2526id%253D*|CAMPAIGN_UID|*" />
  </a>
  <br>
  <a href="https://securepubads.g.doubleclick.net/gampad/jump?iu=%2F${network}%2F${adUnit.ad}&sz=${adUnit.size}&clkk=*|UNIQID|*_*|CAMPAIGN_UID|*_*|DATE:d/m/y|*&ptt=21&t=Site%3D${adUnit.site}%26email_pos%3D${adUnit.placement}&clkp=${adUnit.placement}&url=us8.campaign-archive.com%252F%253Fe%253D*|UNIQID|*%2526u%253D*|EMAIL_UID|*%2526id%253D*|CAMPAIGN_UID|*&click_type=wta" >
    Why this ad?
  </a>
  `

  const bundleAd = `  <a href="https://bundle.villagemedia.ca/click/?uid=*|UNIQID|*&cid=*|CAMPAIGN_UID|*_*|DATE:mdy|*_${adUnit.placement}&f=https://${adUnit.site}" target="_blank">
    <img ${adUnit.ad && adUnit.ad.includes("Alert") ? 'style="max-height:34px!important;width:100%;" ' : 'style="width:100%;" '}width="${adUnit.ad && adUnit.ad.includes("Alert") ? '636' : '600'}" ${adUnit.ad && adUnit.ad.includes("Alert") ? 'height="34"' : ''} src="https://bundle.villagemedia.ca/view/?iu=/${network}/${adUnit.parentUnit ? adUnit.parentUnit : ''}${adUnit.ad}&sz=${adUnit.size}&t=site%3D${adUnit.site}${ads.targeting ? ads.targeting.map(ad => `%26${ad.key}%3D${ad.value}`) : ''}${adUnit.group ? `%26newsletter%3D${encodeURIComponent(adUnit.group)}` : ''}%26newsletterplacement%3D${adUnit.placement}&uid=*|UNIQID|*&cid=*|CAMPAIGN_UID|*_*|DATE:mdy|*_${adUnit.placement}&url=${adUnit.site}&s=${adUnit.siteId}" />
  </a>`

  function getSiteIdByUrl(urlToFind) {
    const site = sites.find(site => site.url === urlToFind);
    return site ? site.foreign_keys.site_id : null;
  }

  function getSizeById(adUnitName) {
    const size = ads.units.find(ad => ad.id === adUnitName);
    return size ? size.size : null
  }

  function getParentBySite(siteId) {
    const parentUnit = ads.topLevelUnits.find(parent => parent.siteId === siteId)
    return parentUnit ? parentUnit.parent : null
  }

  const handleChange = (e) => {
    const site = e.site ? e.site : adUnit.site;
    const ad = e.ad ? e.ad : adUnit.ad;
    const placement = e.placement ? e.placement : adUnit.placement;
    const service = e.service ? e.service : adUnit.service;
    const siteId = getSiteIdByUrl("https://" + site);
    const parentUnit = ads.topLevelUnits ? getParentBySite(siteId) : null
    const group = e.group ? e.group : adUnit.group
    let size = getSizeById(ad);

    setAdUnit({ site, ad, placement, size, service, siteId, group, parentUnit });
    setPreview(false);
  }

  const copyToClipboard = (adCode) => {
    navigator.clipboard.writeText(adCode);
    setAlert(true);
  };

  const previewAd = () => {
    setPreview(true)
  }

  return (
    <Container maxWidth="lg">
      <Box component="form">
        <FormControl fullWidth margin="normal">
          <InputLabel id="service-label">Select Your Service</InputLabel>
          <Select
            labelId="service-label"
            id="service"
            label="Select Your Service"
            value={adUnit.service}
            onChange={(e) => handleChange({ service: e.target.value })}
          >
            <MenuItem value="bundle">Bundle</MenuItem>
            {partnerSite === "Village Media" && (<MenuItem value="gam">GAM</MenuItem>)}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel id="site-label">Select Your Site</InputLabel>
          <Select
            labelId="site-label"
            id="site"
            label="Select Your Site"
            value={adUnit.site}
            onChange={(e) => handleChange({ site: e.target.value })}
          >
            {sites.map(site => (
              site.partner === partnerSite &&
              <MenuItem key={site.foreign_keys.site_id} value={site.url.replace("https://", "")}>{site.name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel id="ad-label">Select Your Ad Unit</InputLabel>
          <Select
            labelId="ad-label"
            id="ad"
            label="Select Your Ad Unit"
            value={adUnit.ad}
            onChange={(e) => handleChange({ ad: e.target.value })}
          >
            {ads.units.map(unit => (
              <MenuItem key={unit.id} value={unit.id}>{unit.id} ({unit.size})</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel id="placement-label">Select Your Ad Placement</InputLabel>
          <Select
            labelId="placement-label"
            id="placement"
            label="Select Your Ad Placement"
            value={adUnit.placement}
            onChange={(e) => handleChange({ placement: e.target.value })}
          >
            {ads.placements.map(placement => (
              <MenuItem key={placement.placement} value={placement.placement}>{placement.placement}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {ads.groups && (<FormControl fullWidth margin="normal">
          <InputLabel id="placement-label">Select Your Email Newsletter</InputLabel>
          <Select
            labelId="placement-label"
            id="newsletter"
            label="Select Your Email Newsletter"
            value={adUnit.group}
            onChange={(e) => handleChange({ group: e.target.value })}
          >
            {ads.groups.map(group => (
              <MenuItem key={group.group} value={group.group}>{group.group}</MenuItem>
            ))}
          </Select>
        </FormControl>
        )}
      </Box>

      <Typography variant="h5" mt={3}>Email Ad Tag:</Typography>
      {adUnit.service === "gam" && (
        <Box>
          {alert && (
            <Alert severity='success'>Tag copied to Clipboard</Alert>
          )}
          {preview && (
            <div dangerouslySetInnerHTML={{ __html: gamAd }} />
          )}
          <Button onClick={() => copyToClipboard(gamAd)}>Copy to Clipboard</Button>
          <Button onClick={() => previewAd()}>Preview Ad Unit</Button>
          <pre>
            <code>
              {gamAd}
            </code>
          </pre>
        </Box>
      )}
      {adUnit.service === "bundle" && (
        <Box>
          {alert && (
            <Alert severity='success'>Tag copied to Clipboard</Alert>
          )}

          <Button onClick={() => copyToClipboard(bundleAd)}>Copy to Clipboard</Button>
          <pre>
            <code>
              {bundleAd}
            </code>
          </pre>
        </Box>
      )}
    </Container>
  );
}

export default AdUnits;
